.contact-page {
  padding: 3.13rem 0;

  .title-block {
    margin-bottom: 3.13rem;
  }

  form {
    max-width: 53rem;
    margin: 0 auto;

    label,
    input[type="text"],
    input[type="email"],
    textarea,
    select {
      display: block;
      width: 100%;
    }

    label {
      margin-bottom: 0.3rem;
      font-size: 1.1rem;
      color: var(--secondary-text-color);
    }

    .errorlist {
      margin-top: 0.3rem;
      font-size: 1.1rem;
      color: var(--error-text-color);
      li {
        margin-bottom: 0.3rem;
      }
    }

    p:not(:first-child) {
      margin-top: 1rem;
    }

    .buttons {
      margin-top: 1.88rem;
      text-align: center;

      > * {
        margin: 0 0.3rem;
        vertical-align: middle;
      }
    }
  }
}
