body > header {
  padding: 5rem 0;
  background-color: var(--secondary-text-color);
  background-image: url('../img/banner.jpg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  @include for-phone-only {
    background-image:
      linear-gradient(to bottom, rgba(black, 0.3) 0%, transparent 100%),
      url('../img/banner.jpg');
  }

  &.large {
    .header-content {
      img {
        display: block;
        width: 20rem;
        width: min(20rem, 100%);
      }

      .title {
        @include font-hero-title;
        margin-top: 4.5rem;
      }

      .subtitle {
        @include font-hero-subtitle;
        margin-top: 1.2rem;
      }

      @include for-phone-only {
        text-align: center;

        img {
          margin: 0 auto;
        }
      }

      nav {
        margin-top: 3.75rem;
      }
    }
  }

  &.small {
    .header-content {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center;
      @include grid-gap(3rem);

      > a {
        width: 20rem;
        width: min(20rem, 100%);

        img {
          display: block;
          width: 100%;
        }
      }

      @include for-phone-only {
        text-align: center;
        justify-content: center;
      }
    }

    @include for-phone-only {
      padding: 3rem 0;
    }
  }

  img {
    image-rendering: pixelated;
  }

  nav {
    ul {
      display: flex;
      flex-wrap: wrap;
      @include grid-gap(1.4rem);

      @include for-phone-only {
        justify-content: center;
      }
    }
  }
}
