.richtext {
  h1 {
    font-size: 140%;
    font-weight: 500;
  }

  h2 {
    font-size: 130%;
    font-weight: 400;
  }

  h3 {
    font-size: 110%;
    font-weight: 400;
  }

  ul {
    list-style: disc;
    padding-left: 3rem;
  }

  ol {
    list-style: decimal;
    padding-left: 3rem;
  }

  h1, h2, h3 {
    &:not(:first-child) {
      margin-top: 1rem;
    }
  }

  h1, h2, h3, p, ul, ol {
    &:not(:last-child) {
      margin-bottom: 0.8rem;
    }
  }
}
