.icon-links {
  ul {
    @include inline-gap(0.3rem);

    li {
      display: inline-block;

      a {
        $size: 2.5rem;
        display: inline-block;
        width: $size;
        height: $size;
        line-height: $size * 1.06;
        font-size: 1.3rem;
        text-align: center;
        color: var(--primary-text-color);
        background-color: var(--overlay-background-color);
        border-radius: 50%;
        @include animate-transition(background-color);

        &:hover {
          background-color: var(--accent-background-hover-color);
        }
      }
    }
  }
}
