.primary-button, .secondary-button, .header-button {
  display: inline-block;
  padding: 0.8rem 1.1rem;
  border-radius: $button-border-radius;
  border: 0;
  cursor: pointer;
  @include font-button;
  @include animate-transition(background-color);

  &:hover {
    color: var(--overlay-text-color);
  }
}

.primary-button {
  background-color: var(--accent-color);

  &:hover {
    background-color: var(--accent-hover-color);
  }
}

.secondary-button {
  background-color: var(--secondary-text-color);

  &:hover {
    background-color: var(--primary-text-color);
  }
}

.header-button {
  background-color: rgba(black, 0.3);
  border: 0.2rem solid var(--overlay-text-color);
  backdrop-filter: blur(6px);

  &:hover {
    background-color: rgba(black, 0.6);
  }
}
