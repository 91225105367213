body {
  @include font-body;
  font-family: Rubik, Helvetica, Arial, sans-serif;
  word-wrap: break-word;
  background-color: var(--primary-background-color);

  @media screen and (resolution >= 2dppx) {
    -webkit-font-smoothing: antialiased;
  }
}
