.team-person {
  &:not(:last-child) {
    margin-bottom: 3rem;
  }

  .flex-grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    @include grid-gap(2rem);
  }

  .photo {
    flex: 0 0 auto;
    width: 12.5rem;
    height: 12.5rem;
    border-radius: 50%;
    overflow: hidden;
    background-position: center;
    background-size: cover;
    background-color: var(--overlay-background-color);
  }

  .content {
    flex: 1 1 25rem;

    .title {
      @include font-title-2;
      margin-bottom: 0.8rem;
    }

    .subtitle {
      @include font-title-3;
      margin-bottom: 1rem;
    }

    .description {
      margin-top: 1.5rem;
    }

    .icon-links {
      margin-top: 1rem;
    }
  }
}
