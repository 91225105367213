.home-page {
  .home-section {
    padding: 3.13rem 0;

    &:nth-child(even) {
      background-color: var(--secondary-background-color);
    }

    .title-block {
      margin-bottom: 3.13rem;
    }

    .training-organization {
      margin-top: 3.13rem;
    }
  }
}
