.backend-messages {
  @include font-sidenote;
  margin-top: 3.13rem;

  li {
    padding: 1.25rem;
    border-radius: $card-border-radius;
    background-color: var(--overlay-background-color);

    &:not(:last-child) {
      margin-bottom: 0.63rem;
    }

    &.success {
      background-color: var(--success-color);
    }

    &.warning {
      background-color: var(--warning-color);
    }

    &.error {
      background-color: var(--error-color);
    }
  }
}
