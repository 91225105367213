.page-content {
  &.outer {
    margin: 0 auto;
    max-width: 1350px;
  }

  &.inner {
    padding: 0 4%;
  }
}
