.terms-page {
  padding: 3.13rem 0;

  h2 {
    @include font-title-2;
    margin: 2.5rem 0 1rem 0;
  }

  p {
    margin-top: 1rem;
  }
}
