.projects-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(19rem, 1fr));
  grid-template-columns: repeat(auto-fill, minmax(min(19rem, 100%), 1fr));
  gap: 1rem;

  .project {
    a {
      display: block;
      height: 100%;
      background-color: var(--overlay-background-color);
      border-radius: $card-border-radius;
      overflow: hidden;
      box-shadow: $card-shadow;
      @include animate-transition((transform, box-shadow));

      &:hover {
        transform: scale(1.02);
        box-shadow: $card-hover-shadow;
      }

      .thumb {
        padding-bottom: 72.22%;
        background-position: center;
        background-size: cover;
        background-color: var(--secondary-background-color);
      }

      .content {
        padding: 1.25rem;

        .title {
          @include font-card-title;
          margin-bottom: 0.94rem
        }

        .subtitle {
          @include font-card-content;
        }
      }
    }
  }
}
