.project-detail-page {
  padding: 3.13rem 0;

  .title-block {
    margin-bottom: 3.13rem;
  }

  .image {
    display: block;
    max-width: 800px;
    margin: 0 auto 3.13rem auto;

    img {
      display: block;
      margin: 0 auto;
      max-width: 100%;
    }
  }

  .icon-links {
    text-align: center;
    margin-top: 3.13rem;
  }
}
