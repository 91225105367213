.training-detail-page {
  padding: 3.13rem 0;

  .title-block {
    margin-bottom: 3.13rem;
  }

  .image {
    max-width: 800px;
    margin: 0 auto 3.13rem auto;

    img {
      display: block;
      margin: 0 auto;
      max-width: 100%;
    }
  }

  .about-and-infos {
    margin-bottom: 3.13rem;

    .flex-grid {
      $gap: 2rem;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: flex-start;
      @include grid-gap($gap);

      .about {
        flex: 1 1 400px;
      }

      .infos {
        flex: 0 0 500px;
        flex-basis: min(500px, calc(100% - #{$gap}));
      }
    }
  }

  .about {
    > h2 {
      @include font-title-2;
      margin-bottom: 1.25rem;

      &:not(:first-child) {
        margin-top: 2.5rem;
      }
    }
  }

  .infos {
    background-color: var(--overlay-background-color);
    border-radius: $card-border-radius;
    padding: 1.88rem;
    box-shadow: $card-shadow;

    h2 {
      font-weight: 500;

      &:not(:first-child) {
        margin-top: 1.25rem;
      }
    }

    .participants {
      margin-bottom: 1.88rem;

      label, input {
        display: block;
      }

      .slider {
        display: flex;
        align-items: center;
        margin-top: 0.63rem;

        .value {
          flex: 0 0 2.1rem;
          font-size: 1.88rem;
          margin-right: 1.25rem;
        }

        input {
          flex: 1 1 0;
        }
      }
    }

    .price-total {
      text-align: center;
      font-size: 1.88rem;
    }

    .price-base {
      text-align: center;
      font-size: 1.13rem;
      color: var(--secondary-text-color);
    }

    .buttons {
      margin-top: 1.88rem;
      text-align: center;
    }
  }
}
