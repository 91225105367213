:root {
  $accent-color: #DC701F;

  --accent-color: #{$accent-color};
  --accent-hover-color: #{darken($accent-color, 20%)};
  --accent-background-color: #{rgba($accent-color, 0.12)};
  --accent-background-hover-color: #{rgba($accent-color, 0.24)};

  --primary-text-color: #1E1A17;
  --secondary-text-color: #65574E;
  --overlay-text-color: #FFF5EE;
  --error-text-color: #ec4a4a;

  --primary-background-color: #F6ECE5;
  --secondary-background-color: #EBDBD2;
  --overlay-background-color: #{rgba(#FFFFFF, 0.7)};

  --success-color: #92C98F;
  --warning-color: #D9A581;
  --error-color: #DB8C8C;
}
