// From: https://www.cssportal.com/style-input-range/

input[type=range] {
  height: 30px;
  -webkit-appearance: none;
  background: none;
}
input[type=range]:focus {
  outline: none;
}
input[type=range]::-webkit-slider-runnable-track {
  width: 100%;
  height: 3px;
  cursor: pointer;
  background: rgba(black, 0.15);
  border-radius: 3px;
  box-shadow: 0;
  border: 0;
}
input[type=range]::-webkit-slider-thumb {
  height: 24px;
  width: 24px;
  border-radius: 12px;
  background: var(--accent-color);
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: -10.5px;
  box-shadow: 0;
  border: 0;
}
input[type=range]:focus::-webkit-slider-runnable-track {
  background: rgba(black, 0.15);
}
input[type=range]::-moz-range-track {
  width: 100%;
  height: 3px;
  cursor: pointer;
  background: rgba(black, 0.15);
  border-radius: 3px;
  box-shadow: 0;
  border: 0;
}
input[type=range]::-moz-range-thumb {
  height: 24px;
  width: 24px;
  border-radius: 12px;
  background: var(--accent-color);
  cursor: pointer;
  box-shadow: 0;
  border: 0;
}
input[type=range]::-ms-track {
  width: 100%;
  height: 3px;
  cursor: pointer;
  background: transparent;
  border-color: transparent;
  color: transparent;
}
input[type=range]::-ms-fill-lower {
  background: rgba(black, 0.15);
  border-radius: 6px;
  box-shadow: 0;
  border: 0;
}
input[type=range]::-ms-fill-upper {
  background: rgba(black, 0.15);
  border-radius: 6px;
  box-shadow: 0;
  border: 0;
}
input[type=range]::-ms-thumb {
  margin-top: 1px;
  height: 24px;
  width: 24px;
  border-radius: 12px;
  background: var(--accent-color);
  cursor: pointer;
  box-shadow: 0;
  border: 0;
}
input[type=range]:focus::-ms-fill-lower {
  background: rgba(black, 0.15);
}
input[type=range]:focus::-ms-fill-upper {
  background: rgba(black, 0.15);
}
