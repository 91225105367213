input[type="text"],
input[type="email"],
textarea,
select {
  padding: 0.88rem;
  background-color: var(--overlay-background-color);
  border: 0.19rem solid rgba(black, 0.1);
  border-radius: $button-border-radius;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  &:focus {
    outline: none;
    border-color: rgba(black, 0.2);
  }
}
