// Import external fonts

@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@400;500&display=swap');

// Font styles

@mixin font-body {
  font-size: 1.25rem;
  font-weight: 400;
  line-height: 1.4;
  color: var(--primary-text-color);
}

@mixin font-title-1 {
  font-size: 2.88rem;
  font-weight: 400;
  line-height: 1.0;
  text-transform: uppercase;
  color: var(--primary-text-color);

  @include for-phone-only {
    font-size: 2.2rem;
  }
}

@mixin font-title-2 {
  font-size: 2rem;
  font-weight: 500;
  line-height: 1.0;
  color: var(--primary-text-color);

  @include for-phone-only {
    font-size: 1.8rem;
  }
}

@mixin font-title-3 {
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 1.0;
  color: var(--secondary-text-color);

  @include for-phone-only {
    font-size: 1.4rem;
  }
}

@mixin font-card-title {
  font-size: 1.63rem;
  font-weight: 500;
  line-height: 1.2;
  color: var(--primary-text-color);
}

@mixin font-card-content {
  font-size: 1.13rem;
  font-weight: 400;
  line-height: 1.3;
  color: var(--primary-text-color);
}

@mixin font-card-note {
  font-size: 1.13rem;
  font-weight: 400;
  line-height: 1.3;
  text-transform: uppercase;
  color: var(--secondary-text-color);
}

@mixin font-see-all {
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 1.3;
  text-transform: uppercase;
  color: var(--accent-color);

  @include for-phone-only {
    font-size: 1.3rem;
  }
}

@mixin font-button {
  font-size: 1.25rem;
  font-weight: 500;
  line-height: 1.3;
  text-transform: uppercase;
  color: var(--overlay-text-color);
}

@mixin font-hero-title {
  font-size: 2.5rem;
  font-weight: 500;
  line-height: 1.2;
  color: var(--overlay-text-color);

  @include for-phone-only {
    font-size: 2.2rem;
  }
}

@mixin font-hero-subtitle {
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 1.4;
  color: var(--overlay-text-color);
}

@mixin font-sidenote {
  font-size: 1.13rem;
  font-weight: 400;
  line-height: 1.3;
  color: var(--primary-text-color);
}
