// Variables

$card-border-radius: 0.4rem;
$button-border-radius: 0.5rem;

// Mixins

@mixin inline-gap($gap) {
  > *:not(:last-child) {
    margin-right: $gap;
  }
}

@mixin grid-gap($gap) {
  margin: (-$gap) 0 0 (-$gap);

  > * {
    margin: $gap 0 0 $gap;
  }
}
